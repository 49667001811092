/* eslint-disable @typescript-eslint/no-unused-vars */
'use server';

import { SupportedLanguage } from '@prisma/client';
import { getTranslations as nextIntlGetTranslations } from 'next-intl/server';
import { cookies } from 'next/headers';
import { z } from 'zod';
import { getCurrentUserSchool } from '~/lib/school/school.authorize-url';

const AcceptedLocals = z.nativeEnum(SupportedLanguage);

const FORCE_LOCALE_STORE: { locale: SupportedLanguage | null } = {
  locale: null,
};

const setForceLocaleStore = (locale: SupportedLanguage | null) => {
  FORCE_LOCALE_STORE.locale = locale;
};

export const getForcedI18n = async (local?: SupportedLanguage | string | {}) => {
  const safeLocal = AcceptedLocals.safeParse(local);
  if (!safeLocal.success) {
    return nextIntlGetTranslations();
  }
  setForceLocaleStore(safeLocal.data);
  return nextIntlGetTranslations();
};

export const getCurrentLocal = async (): Promise<SupportedLanguage> => {
  let locale: SupportedLanguage = 'en';

  if (FORCE_LOCALE_STORE.locale) {
    locale = FORCE_LOCALE_STORE.locale;
  } else {
    try {
      const currentSchool = await getCurrentUserSchool({
        disableAuthorizationVerification: true,
      });
      locale = currentSchool.language;
    } catch (_e) {
      // Nothing
    }
  }

  const cookieList = cookies();
  const userLanguage = cookieList.get('user-language')?.value;
  if (userLanguage) {
    try {
      locale = AcceptedLocals.parse(userLanguage);
    } catch (_e) {
      // Nothing
    }
  }

  return locale;
};
