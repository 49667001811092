'use client';

import type { ErrorParams } from '@/types/next';
import Link from 'next/link';
import { useEffect } from 'react';
import { Typo } from '~/components/atoms/typography/Typo';
import { Badge } from '~/components/catalyst/badge-catalyst';
import { Footer } from '~/components/layout/Footer';
import { BaseHeaderClient } from '~/components/layout/header/BaseHeaderClient';
import { buttonVariants } from '~/components/ui/button';
import { ContactSupportDialog } from '~/features/support/ContactSupportDialog';
import { cn } from '~/lib';
import { logger } from '~/lib/logger/logger';

export default function RouteError({ error }: ErrorParams) {
  useEffect(() => {
    // Log the error to an error reporting service
    logger.error(error);
  }, [error]);

  return (
    <div className={cn('flex h-full w-full flex-col bg-gray-10')}>
      <BaseHeaderClient></BaseHeaderClient>
      <div style={{ flex: 1 }}>
        <main className="my-8 flex h-full flex-col items-center gap-8">
          <div className="space-y-3 text-center">
            <Badge color="cyan">400</Badge>
            <Typo variant="h1">Sorry, some error occurred.</Typo>
            <Typo variant="default">
              We don't really understand this error. If you think this is a mistake,
              please contact us.
            </Typo>
          </div>
          <div className="flex items-center gap-4">
            <Link href="/" className={buttonVariants({ variant: 'contrast' })}>
              Go back home
            </Link>
            <ContactSupportDialog />
          </div>
        </main>
      </div>
      <Footer />
    </div>
  );
}
