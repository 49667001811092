'use client';

import { SelectTrigger } from '@radix-ui/react-select';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/navigation';
import { useCookie } from 'react-use';
import type { ButtonProps } from '~/components/ui/button';
import { Button } from '~/components/ui/button';
import { Select, SelectContent, SelectItem } from '~/components/ui/select';
import { LANGUAGE } from '~/lib/language.const';
import { getCurrentLocal } from './getCurrentLocal';

export const ToggleLanguage = (props: ButtonProps) => {
  const { data } = useQuery({
    queryKey: ['i18n-current-locale'],
    queryFn: async () => {
      const result = await getCurrentLocal();
      return result;
    },
  });
  const queryClient = useQueryClient();
  const [, setUserLanguage] = useCookie('user-language');
  const router = useRouter();

  const currentLanguage = LANGUAGE[data ?? 'en'];

  return (
    <Select
      value={data ?? 'en'}
      onValueChange={async (newValue) => {
        if (newValue === 'school') {
          setUserLanguage('school-default', {});
        } else {
          setUserLanguage(newValue, {});
        }

        queryClient.invalidateQueries({
          queryKey: ['i18n-current-locale'],
        });
        router.refresh();
      }}
    >
      <SelectTrigger asChild>
        <Button size="sm" variant="ghost" {...props}>
          {currentLanguage.emoji}
        </Button>
      </SelectTrigger>
      <SelectContent>
        <SelectContent>
          {Object.entries(LANGUAGE).map(([key, { emoji, name }]) => (
            <SelectItem key={key} value={key}>
              <span className="mr-2">{emoji}</span>
              <span>{name}</span>
            </SelectItem>
          ))}
          <SelectItem value="school">School default</SelectItem>
        </SelectContent>
      </SelectContent>
    </Select>
  );
};
