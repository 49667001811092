'use client';

import { Moon, SunMedium } from 'lucide-react';
import { useIsClient } from 'usehooks-ts';
import { Button } from '~/components/ui/button';
import { cn } from '~/lib';
import { useTheme } from './ThemeProvider';

export const ToggleThemeButton = () => {
  const theme = useTheme();
  const isClient = useIsClient();

  if (!isClient) {
    theme.isDark = true;
  }

  return (
    <Button
      className="!py-2 "
      onClick={() => {
        theme.toggle();
      }}
      variant="ghost"
      size="sm"
    >
      <SunMedium
        size={20}
        className={cn('transition-all ', {
          '-rotate-90 scale-0': theme.isDark,
          'rotate-0 scale-100': !theme.isDark,
        })}
      />
      <Moon
        size={20}
        className={cn('absolute transition-all', {
          'rotate-0 scale-100': theme.isDark,
          'rotate-90 scale-0': !theme.isDark,
        })}
      />
    </Button>
  );
};
